import { format } from 'date-fns/format';
import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';

import { dateFnsFormats } from '~/common/constants';
import { MONTH_IN_MS } from '~/common/format-duration';

export function getVariableTimeString(value: string) {
  const date = new Date(value);

  if (Date.now() - date.getTime() < MONTH_IN_MS) {
    return formatDistanceToNow(date, { addSuffix: true });
  }

  return format(date, dateFnsFormats.date);
}
