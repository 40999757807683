// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type UpdateBranchBasicInfoFragment = { __typename: 'UpdateBranch', id: string, name: string };

export const UpdateBranchBasicInfoFragmentDoc = gql`
    fragment UpdateBranchBasicInfo on UpdateBranch {
  __typename
  id
  name
}
    `;