// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type UpdateActorFragment = { __typename: 'Update', id: string, actor?: { __typename: 'Robot', id: string, displayName: string } | { __typename: 'SSOUser', profilePhoto: string, fullName?: string | null, username: string, bestContactEmail?: string | null, id: string, displayName: string } | { __typename: 'User', profilePhoto: string, fullName?: string | null, username: string, bestContactEmail?: string | null, id: string, displayName: string } | null };

export const UpdateActorFragmentDoc = gql`
    fragment UpdateActor on Update {
  __typename
  id
  actor {
    __typename
    id
    displayName
    ... on UserActor {
      profilePhoto
      fullName
      username
      bestContactEmail
    }
  }
}
    `;