import { mergeClasses } from '@expo/styleguide';
import { PropsWithChildren } from 'react';

type InlineCellProps = PropsWithChildren<{
  className?: string;
}>;

export function TableInlineCell({ children, className }: InlineCellProps) {
  return (
    <div
      className={mergeClasses(
        'flex items-center gap-2 rounded-md border border-default px-3 py-0.5',
        className
      )}>
      {children}
    </div>
  );
}
