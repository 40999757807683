import { mergeClasses } from '@expo/styleguide';

import { HeaderTheme, TableHeader } from '~/ui/components/Table/TableHeader';
import { TableHeaderCell } from '~/ui/components/Table/TableHeaderCell';
import { TableHeaderCellText } from '~/ui/components/Table/TableHeaderCellText';

type Props = {
  theme?: HeaderTheme;
  hasActionsColumn?: boolean;
  nested?: boolean;
};

export function UpdatesHeader({ theme, hasActionsColumn, nested = false }: Props) {
  return (
    <TableHeader theme={theme}>
      <TableHeaderCell className="w-full">
        <TableHeaderCellText className={mergeClasses(nested && 'pl-2')}>Update</TableHeaderCellText>
      </TableHeaderCell>
      <TableHeaderCell hideOnMobile>
        <TableHeaderCellText>Commit</TableHeaderCellText>
      </TableHeaderCell>
      <TableHeaderCell hideOnMobile>
        <TableHeaderCellText>Platforms</TableHeaderCellText>
      </TableHeaderCell>
      <TableHeaderCell hideOnMobile>
        <TableHeaderCellText>Runtime version</TableHeaderCellText>
      </TableHeaderCell>
      <TableHeaderCell hideOnMobile>
        <TableHeaderCellText className={mergeClasses(!hasActionsColumn && nested && 'pr-2')}>
          Branch
        </TableHeaderCellText>
      </TableHeaderCell>
      {hasActionsColumn && (
        <TableHeaderCell className={mergeClasses(nested && 'pr-2')} hideOnMobile />
      )}
    </TableHeader>
  );
}
